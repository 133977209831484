import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';

import ShowFaq from '../../components/support/ShowFaq';
import { debounce, renderScoreComment } from '../../utilities/Generic.js'

import { renderErrorWarning, renderErrors } from '../../utilities/Forms.js'

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class CreateTicket extends Component {

  constructor(props) {
    super(props);

    this.handleShowPanel = this.handleShowPanel.bind(this);
    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandleSearch = debounce(this.handleSearch.bind(this), 300);
    this.handleQuestionNotAnswered = this.handleQuestionNotAnswered.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  state = {
    title: "",
    body: "",
    category: "",
    priority: "",
    upload_file: null,

    showFAQSearch: true,
    showTicketForm: false,

    faqs: [],
    search: "",
    loaded: true,
    loading: false,
    showPanelToggle: false,

    updated: "",
    updating: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      loaded: false
    });

    this.debouncedHandleSearch()
  }

   handleSearch() {
    if (this.state.search === "") {
      this.setState({
        loaded: true,
        faqs: []
      });
      return
    }

    this.setState({loading: true})

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/faqs?faqs[search]=${this.state.search}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ faqs: data, loaded: true, loading: false }))
      .catch(error => this.setState({ error, loaded: true, loading: false }))
  }

  handleShowPanel(event) {
    let id = event.currentTarget.id;

    this.setState({
      showId: id,
      showPanelToggle: true
    });
  }

  renderAnswers() {
    const { faqs, search, loaded, loading } = this.state;

    if (loading) {
      return (
        <div>
          <div className="loading-segment">
            <h3>Loading...</h3>
          </div>
        </div>
      )
    }
    else if (loaded === true && faqs.length === 0 && search !== "") {
      return (
        <div>
          <div className="loading-segment">
            <h3>Sorry, no FAQs found. Please create a support ticket.</h3>
          </div>

          <div className="new-ticket-button">
            <div className="button" onClick={this.handleQuestionNotAnswered}>New Ticket</div>
          </div>
        </div>
      );
    }
    else {
      return (
        <div>
          {faqs.map((faq) => (
            <div className="subtle-link" onClick={this.handleShowPanel} id={faq.id} key={faq.id}>
              <div className="faq-segment">
                <h3 className="no-top-margin">{faq.question}</h3>
                <div dangerouslySetInnerHTML={{__html: faq.answer}}></div>
                <div className="inline-score-comments">{renderScoreComment(faq.positive_score)}</div>
              </div>
            </div>
          ))}

          {faqs.length > 0 && (
            <div className="new-ticket-button">
              <div className="button" onClick={this.handleQuestionNotAnswered}>Question not answered by these FAQs?</div>
            </div>
          )}
        </div>
      )
    }
  }

  handleQuestionNotAnswered(event) {
    this.setState({
      "showFAQSearch": false,
      "showTicketForm": true,
      "title": this.state.search
    });
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleSubmit(event, run) {
    this.setState({
      updated: "",
      updating: true
    })

    const formData = new FormData()
    formData.append('ticket[title]', this.state.title)
    formData.append('ticket[category]', this.state.category)
    formData.append('ticket[priority]', this.state.priority)
    formData.append('ticket[ticket_actions_attributes][][body]', this.state.body)
    if (this.state.upload_file !== null) {
      formData.append('ticket[ticket_actions_attributes][][image]', this.state.upload_file)
    }

    const requestOptions = {
      method: 'POST',
      body: formData,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/tickets/`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      }
      else {
        this.setState({
          id: data.id,
          updated: true,
          updating: false,
          errors: ""
        })
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  handleFileUpload(event) {
    let name = event.target.name;
    let value = event.target.files[0];

    this.setState({[name]: value});
  }

  renderUpdated(updated) {
    if (updated) {
      return (
        <div>
          Ticket created!
        </div>
      );
    }
  }

  render() {
    const { title, body, category, priority, id, search, showFAQSearch, showTicketForm, updated, updating, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (updated && id) {
      return <Redirect to={`/support/${id}`}/>
    }

    if (showFAQSearch) {
      return (
        <div>
          <SetTitle title={`Create Ticket | Support`} />
          <div className="settings big-settings inline-faq-search">
            <div className="row">
              <h3 className="inline-block small-bottom-margin">Please type your question below.</h3>
              <p className="bottom-margin">If one of the suggested FAQ does not answer it, you can create a new Support Ticket and a member of our team will be in touch.</p>
              <div className="input-box">
                <input placeholder="Search FAQs" type="text" name="search" value={search} onChange={this.handlePartialChange} />
              </div>

              {this.renderAnswers()}
            </div>

            <SlidingPane isOpen={this.state.showPanelToggle} title="Show FAQ" width="50%"
              onRequestClose={() => {
                this.setState({ showPanelToggle: false });
                this.setState({ showId: null });
              }}>
              <ShowFaq id={this.state.showId} />
            </SlidingPane>
          </div>
        </div>
      )
    }

    if (showTicketForm) {
      return (
        <div>
          <SetTitle title={`Create Ticket | Support`} />
          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            <div className="row">
              <HelpText page={'support'} section={'title'} />

              <label className="column">Ticket Summary:</label>{ renderErrors(errors, 'title') }
              <input className="column" type="text" name="title" value={title} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'support'} section={'body'} />

              <label className="column">Full Ticket Description:</label>{ renderErrors(errors, 'ticket_actions.body', 'description') }
              <textarea aria-required="true" name="body" value={body} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'support'} section={'category'} />
              <label className="column">Category:</label>{ renderErrors(errors, 'category') }
              <select name="category" onChange={this.handleChange} value={category}>
                <option value="">Select</option>
                <option value="Problem">Problem</option>
                <option value="Question">Question</option>
                <option value="Ideas">Ideas</option>
              </select>
            </div>

            <div className="row">
              <HelpText page={'support'} section={'priority'} />
              <label className="column">Priority:</label>{ renderErrors(errors, 'priority') }
              <select name="priority" onChange={this.handleChange} value={priority}>
                <option value="">Select</option>
                <option value="Urgent">Urgent</option>
                <option value="Important">Important</option>
                <option value="No rush">No rush</option>
              </select>
            </div>
            <label className="column">Upload an image for context (optional):</label>
            <input className="column" type="file" name="upload_file" onChange={this.handleFileUpload} accept="image/*, video/*" />

            <input type="submit" value="Create Ticket" disabled={updating} />
            { this.renderUpdated(updated) }
            { renderErrorWarning(errors) }
          </form>
        </div>
      );
    }
  }
}

export default CreateTicket;
