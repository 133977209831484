import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import ConditionalLink from '../../components/shared/ConditionalLink';
import SetTitle from '../../components/shared/SetTitle';

import { formatNewLines } from '../../utilities/Generic.js'
import { spaceAndCapitalize } from '../../utilities/Forms.js'

class UpdateFireLog extends Component {
  constructor(props) {
    super(props);

    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSendEmail = this.handleSendEmail.bind(this);
  }

  state = {
    people: {
      visitor: [],
      resident: [],
      staff: [],
      contractor: [],
      industry_professional: [],
      other: []
    },
    checked_ids: [],
    notes: "",
    amendable: false,
    defaultEmail: "",
    showSendEmailModal: false,

    loaded: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleCheckbox(event) {
    let name = parseInt(event.target.name);
    let checked = this.state.checked_ids.includes(name);

    if (checked) {
      this.setState(prevState => ({
        checked_ids: prevState.checked_ids.filter(element => element !== name)
      }))
    }
    else {
      this.setState(prevState => ({
        checked_ids: prevState.checked_ids.concat(name)
      }))
    }
  }

  handleSubmit(event) {
    // collect every person
    let people = this.state.people

    let fire_log_people = []

    // manipulate person object to fire_log specification
    people.forEach((person) => {
      fire_log_people.push({
        id: person.id,
        name: person.name,
        check_in: person.check_in,
        checked: this.state.checked_ids.includes(person.id),
        type_of_person: person.type_of_person
      })
    })

    const id = this.props.id

    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const json = JSON.stringify({
      "fire_log": {
        "people": fire_log_people,
        "completed": true
      }
    })

    const requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    let errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/fire_logs/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({ updating: false, errors: "" })
        sessionStorage.setItem("updateSuccess", this.completeMessage());
        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  completeMessage() {
    let emailNotice = ""

    if (this.state.defaultEmail) {
      emailNotice = ` Email will be sent to ${this.state.defaultEmail}`
    }
    return "Fire Log completed!" + emailNotice
  }

  openModal(event) {
    this.setState({
      email: this.state.defaultEmail,
      showSendEmailModal: true
    })

    event.preventDefault();
  }

  closeModal() {
    this.setState({
      showSendEmailModal: false
    })
  }

  handleEmailChange(event) {
    const value = event.target.value;

    this.setState({email: value})

    event.preventDefault();
  }

  handleSendEmail() {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const json = JSON.stringify({
      "fire_log": {
        "email": this.state.email
      }
    })

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    let errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/fire_logs/${this.props.id}/send_email`, requestOptions)
    .then(response => {
      if (response.ok) {
        this.closeModal()
        sessionStorage.setItem("updateSuccess", "Fire Log emailed successfully!");
        this.props.closeEditPanel()
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({errors: data})
      } else {
        this.setState({errors: ""})
      }
    })
    .catch(error => this.setState({ error }))
  }

  writeAccess() {
    const access = localStorage.getItem("access_levels");
    return access !== null && (access.includes("admin") || access.includes("emergency"))
  }

  renderErrors() {
    if (this.state.errors.length > 1) {
      this.state.errors.map((error, index) => (
        <div className="error" key={index}>{this.state.errors[index]}</div>
      ))
    }
  }

  renderSaveButton() {
    if (this.props.writeAccess && this.state.amendable) {
      return(
        <div>
          <input type="submit" value="Complete" onClick={this.handleSubmit} disabled={this.state.updating} />
        </div>
      )
    }
  }

  renderSendEmailModal() {
    if (this.state.showSendEmailModal) {
      return (
        <div className="send-email-container">
          <div className="send-email-content">
            <label className="column">Email this Fire Log to the following address:</label>
            <input className="value" name="value" value={this.state.email} onChange={this.handleEmailChange}/>
            <div>
              <button className="send-email-button" value="yes" onClick={this.handleSendEmail}>Send Email</button>
              <button className="send-email-button" value="no" onClick={this.closeModal}>Cancel</button>
            </div>
          </div>
        </div>
      )
    }
  }

  personTypeBlock(personType, people) {
    if (people && people.length !== 0) {
      return (
        <React.Fragment key={personType}>
          <h3 className="widget-title">{personType}</h3>
          {people.map((person) => (
            <div className="inline-grid" key={person.id}>
              <input className="column" type="checkbox" id={person.id} name={person.id} onChange={this.handleCheckbox} disabled={!(this.props.writeAccess && this.state.amendable)} checked={this.state.checked_ids.includes(person.id)} />
              <label className="column checkbox-label" htmlFor={person.id}>
                <div className="person-details">
                  <div>{person.name}</div>
                  <div className="center">-</div>
                  <div>Arrived:</div>
                  <div className="center">-</div>
                  <div className="center">{person.check_in}</div>
                </div>
              </label>
            </div>
          ))}
        </React.Fragment>
      )
    }
  }

  render() {
    const { people, order, notes, user_id, user_name, loaded, unauthorized, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      const total = people.visitor.length + people.resident.length + people.staff.length + people.contractor.length + people.industry_professional.length + people.other.length
      const accountedFor = this.state.checked_ids.length
      const unaccountedFor = total - accountedFor

      return (
          <div className="settings">
            <SetTitle title={`Fire Log | Locations`} />
            <h3>Fire Log submitted by <ConditionalLink url={`/settings/users/${user_id}`} condition={this.props.readAccess}> {user_name}</ConditionalLink></h3>
            <div className="green-button bottom-margin" onClick={this.openModal}>Send Email</div>

              <div className="people-count">
                {order.map((type_of_person) => (
                  <div key={type_of_person}><span className="count-title">{spaceAndCapitalize(type_of_person)}</span>: {people[type_of_person].length}</div>
                ))}
              </div>

              <div className="people-count margin-top">
                <div><span className="count-title">Total</span>: {total}</div>
                <div><span className="count-title">Accounted for</span>: {accountedFor}</div>
                { unaccountedFor > 0 &&
                  <div><span className="count-title">Unaccounted for</span>: {unaccountedFor}</div>
                }
              </div>
            <div className="person-type settings big-settings">

              {order.map((type_of_person) => (
                this.personTypeBlock(spaceAndCapitalize(type_of_person), people[type_of_person])
              ))}

              {notes !== "" && (
                <div className="margin-top">
                  <h3 className="widget-title">Notes</h3>
                  <div>{formatNewLines(notes)}</div>
                </div>
              )}
            </div>
            {this.renderErrors()}
            {this.renderSaveButton()}
            {this.renderSendEmailModal()}
          </div>
      );
    }
  }

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/fire_logs/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        // turned `checked` property into array of checked_ids
        let checked_ids = data.people.filter((person) => person.checked === true).map(person => person.id)
        // seperate people by type_of_person
        let people = {
          "visitor": [],
          "resident": [],
          "staff": [],
          "contractor": [],
          "industry_professional": [],
          "other": []
        };

        data.people.forEach((person) => {
          people[person.type_of_person].push(person)
        })

        this.setState({
          people: people,
          checked_ids: checked_ids,
          order: data.order,
          notes: data.notes,
          amendable: data.amendable,
          user_id: data.user_id,
          user_name: data.user_name,
          defaultEmail: data.default_email,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default UpdateFireLog;
