import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faCalendarTimes } from "@fortawesome/free-solid-svg-icons";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { faCompass } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import { faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { faTools } from "@fortawesome/free-solid-svg-icons";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import ResellerLogo from '../header/ResellerLogo';
import ResellerCSS from '../header/ResellerCSS';

import { displayStaffFulfilment } from '../../utilities/Forms';

class Header extends Component {
  state = {
    unread_replies: localStorage.getItem("last_replies_count"),
    suggested_merges: localStorage.getItem("last_suggested_merges_count"),
    outstanding_actions: localStorage.getItem("last_outstanding_actions_count"),
    error: null
  }

  renderReplyCount(count, error) {
    if (count > 0) {
      return (
        <span className="unread-replies notification-count" title={`You have ${count} unread ticket ${count > 1 ? "replies" : "reply"}!`} >{count}</span>
      )
    }
    if (error) {
      return (
        <span className="unread-replies notification-count" title={"There was an error reading your ticket reply count."}>!</span>
      )
    }
  }

  renderSuggestedMergesCount(count, error) {
    if (typeof count === "string") {
      return (
        <span className="notification-count" title={"Suggestions are being merged in the background. The count will be updated shortly."}>{count}</span>
      )
    }
    if (count > 0) {
      return (
        <span className="notification-count" title={`You have ${count} merge people suggestion${count > 1 ? "s" : ""}!`} >{count}</span>
      )
    }
    if (error) {
      return (
        <span className="notification-count" title={"There was an error reading your suggested merge count."}>!</span>
      )
    }
  }

  renderSuggestedMergeIndicator(count) {
    if (count > 0) {
      return (
        <span className="notification-indicator" />
      )
    }
  }

  renderOutstandingActionsCount(count, error) {
    if (count > 0) {
      return (
        <span className="notification-count" title={`You have ${count} outstanding feedback action${count > 1 ? "s" : ""}!`} >{count}</span>
      )
    }
    if (error) {
      return (
        <span className="notification-count" title={"There was an error reading your outstanding feedback action count."}>!</span>
      )
    }
  }

  formatDate(date, minus) {
    date.setDate(date.getDate() - minus);
    let day = ("0" + date.getDate()).slice(-2)
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    let year = date.getFullYear()

    return `${year}-${month}-${day}`
  }

  renderOutstandingActionsIndicator(count) {
    if (count > 0) {
      return (
        <span className="notification-indicator" />
      )
    }
  }

  render() {
    const { unread_replies, suggested_merges, outstanding_actions, error } = this.state;

    const baseLicense = localStorage.license === "base"

    return (
      <header className="no-print">
        <ResellerLogo />

        <div className="top-header">
          <nav className="left-nav">
            <ul>
              <li><Link to={'/'}><FontAwesomeIcon icon={faHome} /> Home</Link></li>
              <li><Link to={'/locations'}><FontAwesomeIcon icon={faCompass} /> Locations</Link></li>

              <li className="dropdown"><Link to={'/configuration'}><FontAwesomeIcon icon={faTools} /> Configuration</Link>
                <div className="dropdown-content">
                  <div><Link to={'/configuration/devices'}><FontAwesomeIcon icon={faDesktop} /> Devices</Link></div>
                  <div><Link to={'/configuration/profiles'}><FontAwesomeIcon icon={faIdCard} /> Profiles</Link></div>
                  { baseLicense && <div><Link to={'/configuration/feedback'}><FontAwesomeIcon icon={faComment} /> Feedback</Link></div> }
                  { baseLicense && <div><Link to={'/configuration/custom-attributes'}><FontAwesomeIcon icon={faEdit} /> Custom Attributes</Link></div> }
                  { baseLicense && <div><Link to={'/configuration/roles'}><FontAwesomeIcon icon={faUsers} /> Roles </Link></div> }
                  { baseLicense && <div><Link to={'/configuration/tags'}><FontAwesomeIcon icon={faTags} /> People Tags </Link></div> }
                </div>
              </li>

              <li className="dropdown"><Link to={'/people'}><FontAwesomeIcon icon={faUserFriends} /> People {this.renderSuggestedMergeIndicator(suggested_merges)}</Link>
                <div className="dropdown-content">
                  <div><Link to={'/merge-people'}><FontAwesomeIcon icon={faPeopleArrows} /> Merge People</Link></div>
                  <div><Link to={'/suggested-merge-people'}><FontAwesomeIcon icon={faListAlt} /> Suggested Merge People {this.renderSuggestedMergesCount(suggested_merges, error)}</Link></div>
                </div>
              </li>

              { baseLicense && <li><Link to={'/planned-events'}><FontAwesomeIcon icon={faCalendarTimes} /> Planned Events</Link></li> }

              {displayStaffFulfilment() ? (
                <li className="dropdown"><Link to={'/staff-fulfilment'}><FontAwesomeIcon icon={faCalendarAlt} /> Staff Fulfilment</Link>
                  <div className="dropdown-content">
                    <div><Link to={'/worked-hours'}><FontAwesomeIcon icon={faClock} /> Worked Hours</Link></div>
                    <div><Link to={'/bulletins'}><FontAwesomeIcon icon={faBullhorn} /> Bulletins</Link></div>
                  </div>
                </li>
              ) : null}
              
              <li><Link to={'/analysis'}><FontAwesomeIcon icon={faChartLine} /> Analysis</Link></li>

              <li className="dropdown"><Link to={'/exports'}><FontAwesomeIcon icon={faFileDownload} /> Exports {this.renderOutstandingActionsIndicator(outstanding_actions)}</Link>
                <div className="dropdown-content">
                  <div><Link to={'/exports/events'}><FontAwesomeIcon icon={faCalendarTimes} /> Export Events</Link></div>
                  <div><Link to={'/exports/feedback'}><FontAwesomeIcon icon={faComment} /> Export Feedback</Link></div>
                  <div><Link to={'/exports/people'}><FontAwesomeIcon icon={faUserFriends} /> Export People</Link></div>
                  { baseLicense && <div><Link to={'/exports/outstanding-actions'}><FontAwesomeIcon icon={faTasks} /> Outstanding Actions {this.renderOutstandingActionsCount(outstanding_actions, error)}</Link></div> }
                  { baseLicense && <div><Link to={'/exports/actions-taken'}><FontAwesomeIcon icon={faTasks} /> Actions Taken </Link></div> }
                </div>
              </li>

              { baseLicense && <li><Link to={'/imports'}><FontAwesomeIcon icon={faCloudUploadAlt} /> Imports</Link></li> }
              <li><Link to={'/support'}><FontAwesomeIcon icon={faQuestionCircle} /> Support {this.renderReplyCount(unread_replies, error)}</Link></li>
            </ul>
          </nav>

          <nav className="right-nav">
            <ul>
              { !baseLicense && <li><Link to={'/upgrade'}><FontAwesomeIcon icon={faCreditCard} /> Upgrade</Link></li> }
              <li><Link to={'/settings'}><FontAwesomeIcon icon={faCogs} /> Settings</Link></li>
              <li><Link to={'/logout'}><FontAwesomeIcon icon={faSignOutAlt} /> Log Out</Link></li>
            </ul>
          </nav>
        </div>

        <div className="bottom-header">
          <div className="dr-header-text">
            Digital Reception
          </div>
        </div>

        <ResellerCSS />
      </header>
    )
  }

  componentDidMount() {
    let lastRepliesGet = localStorage.getItem("last_replies_get")

    let currentTime = new Date();
    let tenMinutesAgo = currentTime.setMinutes(currentTime.getMinutes() - 10)

    if (lastRepliesGet < tenMinutesAgo) {
      // normal API fetching stuff
      let headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      let requestOptions = {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/tickets/unread_replies`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ unread_replies: data.unread_replies });
          localStorage.setItem("last_replies_count", data.unread_replies)
          localStorage.setItem("last_replies_get", new Date().valueOf())
        })
        .catch(error => this.setState({ error }))
    }

    let lastSuggestedMergesGet = localStorage.getItem("last_suggested_merges_get")

    if (lastSuggestedMergesGet < tenMinutesAgo) {
      // normal API fetching stuff
      let headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      let requestOptions = {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/suggested_merges/count`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ suggested_merges: data.suggested_merges });
          localStorage.setItem("last_suggested_merges_count", data.suggested_merges)
          localStorage.setItem("last_suggested_merges_get", new Date().valueOf())
        })
        .catch(error => this.setState({ error }))
    }
    let lastOutstandingActionsGet = localStorage.getItem("last_outstanding_actions_get")

    if (lastOutstandingActionsGet < tenMinutesAgo && localStorage.license === "base") {
      // normal API fetching stuff
      let headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      let requestOptions = {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/outstanding_actions/outstanding_actions_count`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ outstanding_actions: data.outstanding_actions });
          localStorage.setItem("last_outstanding_actions_count", data.outstanding_actions)
          localStorage.setItem("last_outstanding_actions_get", new Date().valueOf())
        })
        .catch(error => this.setState({ error }))
    }

    let lastForceRefreshGet = localStorage.getItem("last_force_refresh_get")

    if (lastForceRefreshGet < tenMinutesAgo) {
      // normal API fetching stuff
      let headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      let requestOptions = {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/app_force_refresh`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          let previousForceRefreshTime = localStorage.getItem("last_force_refresh_time")
          localStorage.setItem("last_force_refresh_time", data.force_refresh_time)
          localStorage.setItem("last_force_refresh_get", new Date().valueOf())

          if (previousForceRefreshTime < data.force_refresh_time) {
            window.location.reload()
          }
        })
        .catch(error => this.setState({ error }))
    }
  }
}

export default Header;
