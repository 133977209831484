import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SetTitle from '../../components/shared/SetTitle';

import { adminUser, managerUser, renderErrorWarning, capitalizeAllWords, capitalize } from '../../utilities/Forms.js'
import { formatNewLines } from '../../utilities/Generic.js'

class UpdateAction extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleRequestAction = this.handleRequestAction.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    person: "",
    feedback_answers: [],
    check_in: "",
    check_out: "",
    location: "",
    location_id: "",
    action_status: "",
    action_type: "",
    action_outcome: "",
    actions: [],

    loaded: false,
    event_id: "",
    unauthorized: "",
    errors: "",
    error: ""
  };

  colourScore(score) {
    if (score === null) {
      return "grey-text"
    }
    if (score < 0) {
      return "red-text"
    }
    if (score > 0) {
      return "green-text"
    }
    else {
      return ""
    }
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleRequestAction(event) {
    this.setState({
      updating: true
    })

    const endpoint = this.props.event_id ? `request_action_on_event?event_id=${this.props.event_id}` : `request_action_on_answer?feedback_answer_id=${this.props.feedback_answer_id}`;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/outstanding_actions/${endpoint}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({
          updating: false,
          errors: "",
          person: data.person,
          feedback_answers: data.feedback_answers,
          check_in: data.check_in,
          check_out: data.check_out,
          location: data.location,
          location_id: data.location_id,
          action_status: data.action_status,
          actions: data.actions,
          id: data.id
        })
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();

  }

  handleSubmit(event) {
    this.setState({
      updating: true
    })

    let id = this.state.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = {
      "outstanding_action": {
        "action_status": this.state.action_status
      }
    }

    if (this.state.action_type) {
      json["outstanding_action"]["action_type"] = this.state.action_type;
      json["outstanding_action"]["action_outcome"] = this.state.action_outcome;
    }

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(json),
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/outstanding_actions/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({
          updating: false,
          errors: "",
        })
        const recorded = this.state.action_type ? " and recorded" : "";

        sessionStorage.setItem("updateSuccess", `Action updated${recorded} successfully!`);
        this.props.closePanel()
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  colourActionsByStatus(action) {
    switch(action.action_status) {
      case "Open":
        return "open-action"
      case "Started":
        return "started-action"
      case "Awaiting sign-off":
        return "awaiting-sign-off-action"
      case "Blocked":
        return "blocked-action"
      case "Completed":
        return "completed-action"
      case "Abandoned":
        return "abandoned-action"
      default:
        return ""
    }
  }

  renderActionsAndControls() {
    const { action_type, action_status, actions, errors } = this.state;

    if (action_status === null) {
      const type = this.props.feedback_answer_id ? "Feedback Answer" : "Event"
      return (
        <div className="settings big-settings">
          <div className="centered-text">
            <div className="centered-text">This {type} does not currently require follow up action:</div>
            {this.renderFeedbackAnswerFromProps()}
            <br></br>
            {this.renderRequestActionButton(type)}
          </div>
        </div>
      )
    }
    else {
      return (
        <>
          {actions.map((action, index) => (
            <div className="settings big-settings" key={index}>
              <div className="row">
                <label className="column"><span className="strong">Action Submitted by:</span> <Link to={`/settings/users/${action.user_id}`}>{action.user_name}</Link> at {action.date}</label>
                <br></br>
                {action.action_status &&
                  <div className="ticket-body">
                    <div className="body-title">
                      <label className="column strong action-status">Status at time of Action:</label>
                    </div>
                    <div className={`body-content action-status ${this.colourActionsByStatus(action)} not-clickable`}>
                      {action.action_status}
                    </div>
                  </div>
                }
                <div className="ticket-body">
                  <div className="body-title">
                    <label className="column strong">Type:</label>
                  </div>
                  <div className="body-content">
                    {action.action_type}
                  </div>
                </div>
                <div className="ticket-body">
                  <div className="body-title">
                    <label className="column strong">Outcome:</label>
                  </div>
                  <div className="body-content">
                    {action.action_outcome}
                  </div>
                </div>
              </div>
            </div>
          ))}

          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            <div className="row">
              <label className="column">Submit a new Action:</label>{ this.renderErrors('action_type') }
              <select name="action_type" value={action_type} onChange={this.handleChange} data-testid="select-new-action-type">
                <option value="">Select action type</option>
                <option value="email">Email</option>
                <option value="phone call">Phone call</option>
                <option value="conversation">Conversation</option>
              </select>
            </div>
            {this.renderOutcomesBox()}

            <div className="row">
              <label className="column">Action status:</label>
              <select name="action_status" value={action_status} className={`not-clickable ${this.colourActionsByStatus({action_status: capitalize(action_status)})}`} onChange={this.handleChange} data-testid="select-action-status">
                <option value="">All</option>
                <option value="open">Open</option>
                <option value="started">Started</option>
                <option value="awaiting sign-off">Awaiting sign-off</option>
                <option value="blocked">Blocked</option>
                <option value="completed">Completed</option>
                <option value="abandoned">Abandoned</option>
              </select>
            </div>

            { this.renderSaveButton() }
            { renderErrorWarning(errors) }
          </form>
        </>
      )
    }
  }

  renderFeedbackAnswerFromProps() {
    if (this.props.feedback_answer_id) {
      const feedback_answer = this.state.feedback_answers.find((feedback_answer) => feedback_answer.id === this.props.feedback_answer_id)
      return (
        <div className="centered-text">
          <br/>
          <div>Question: <strong><Link to={`/configuration/feedback/${feedback_answer.feedback_type_id}/question/${feedback_answer.question_id}`}>{feedback_answer.question}</Link></strong></div>
          <div>Answer: <strong>{feedback_answer.answer}</strong></div>
        </div>
      )
    }
  }

  renderOutcomesBox() {
    if (this.state.action_type) {
      return (
        <div className="row">
          <label className="column">Summary of action outcomes:</label>{ this.renderErrors('error') }
          <textarea aria-required="true" name="action_outcome" value={this.state.action_outcome} onChange={this.handleChange} />
        </div>
      )
    }
  }

  renderProblemType(problem_type) {
    if (this.state.event_id || this.state.cool_event_id) {
      const id = this.state.event_id || this.state.cool_event_id;

      return <div><Link className="visible-link" to={`/exports/events/${id}`}>Event</Link> Feedback</div>
    }

    return capitalizeAllWords(problem_type.replace("cool ", ""))
  }

  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderRequestActionButton(type) {
    if (adminUser() || managerUser()) {
      return <input type="submit" value={`Require follow up actions on this ${type}`} disabled={this.state.updating} onClick={this.handleRequestAction} />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to do this" disabled="disabled" />
    }
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <input type="submit" value="Save" />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {
    const { person, feedback_answers, problem_type, problem, date_of_problem, check_in, check_out, location, location_id, event_id, cool_event_id, loaded, unauthorized, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div>
          <SetTitle title={`Update Actions | Outstanding Actions`} />

          <h3>Update Actions</h3>
          <form className="settings big-settings">
            <div className="table soft-table">
              <div className="tr">
                <label className="pcolumn strong">Name:</label>
                <Link to={`/people/${person.id}`}><label className="prightcolumn link">{person.full_name}</label></Link>
              </div>

              <div className="tr">
                <label className="pcolumn strong">Type of person:</label>
                <label className="prightcolumn"> {person.type_of_person}</label>
              </div>

              <div className="tr">
                <label className="pcolumn strong">Location:</label>
                <Link to={`/locations/${location_id}`}><label className="prightcolumn link"> {location}</label></Link>
              </div>

              {problem_type !== null ? (
                <div className="tr">
                  <label className="pcolumn strong">Problem Type:</label>
                  <label className="prightcolumn">{this.renderProblemType(problem_type)}</label>
                </div>
              ) : ( <></> )}

              {event_id !== null || cool_event_id !== null ? (
                <div className="tr">
                  <label className="pcolumn strong">Arrived:</label>
                  <label className="prightcolumn">{check_in}</label>
                </div>
              ) : ( <></> )}

              {event_id !== null || cool_event_id !== null ? (
                <div className="tr">
                  <label className="pcolumn strong">Left:</label>
                  <label className="prightcolumn">{check_out}</label>
                </div>
              ) : ( <></> )}

              {date_of_problem !== null ? (
                <div className="tr">
                  <label className="pcolumn strong">Date of Problem:</label>
                  <label className="prightcolumn">{date_of_problem}</label>
                </div>
              ) : ( <></> )}

              {problem !== null ? (
                <div className="tr">
                  <label className="pcolumn strong">Problem:</label>
                  <label className="prightcolumn align-right">
                    <div>{formatNewLines(problem)}</div>
                  </label>
                </div>
              ) : ( <></> )}
            </div>

            {feedback_answers !== undefined ? (
              <div className="table big-table top-padding">
                <div className="tr heading">
                  <div className="th">Question</div>
                  <div className="th">Answer</div>
                  <div className="th">Action Needed?</div>
                </div>
                {feedback_answers.map((feedback_answer) => (
                  <div className="tr" id={feedback_answer.id} key={feedback_answer.id}>
                    <Link to={`/configuration/feedback/${feedback_answer.feedback_type_id}/question/${feedback_answer.question_id}`} className="td visible-link">{feedback_answer.question}</Link>
                    <div className={`td ${this.colourScore(feedback_answer.score)}`}>{feedback_answer.answer}</div>
                    <div className={`td center`}>{feedback_answer.action_needed ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
                  </div>
                ))}
              </div>
            ) : ( <></> )}
          </form>

          {this.renderActionsAndControls()}
        </div>
      );
    }
  }

  componentDidMount() {
    // This allows us to take either an OustandingAction ID or a FeedbackAnswer ID and render the same view
    let endpoint = ""

    if (this.props.outstanding_action_id) {
      endpoint = this.props.outstanding_action_id
    }
    else if (this.props.feedback_answer_id) {
      endpoint = `show_from_answer?feedback_answer_id=${this.props.feedback_answer_id}`
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/outstanding_actions/${endpoint}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({
            error: JSON.stringify(response.body),
            unauthorized: true
          })
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          person: data.person,
          feedback_answers: data.feedback_answers,
          check_in: data.check_in,
          check_out: data.check_out,
          location: data.location,
          location_id: data.location_id,
          action_status: data.action_status,
          action_outcome: data.action_outcome,
          actions: data.actions,
          id: data.id,
          problem: data.problem,
          problem_type: data.problem_type,
          date_of_problem: data.date_of_problem,
          event_id: data.event_id,
          cool_event_id: data.cool_event_id,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default UpdateAction;
