import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import HelpText from '../../components/help/HelpText';

import { adminUser, renderErrorWarning, renderErrors, capitalizeAllWords } from '../../utilities/Forms.js'

import { faArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DragDropContext, Draggable  } from 'react-beautiful-dnd';
import Droppable from '../../components/shared/StrictModeDroppable'

class UpdateFireLogSettings extends Component {

  constructor(props) {
    super(props);

    this.onDragEnd = this.onDragEnd.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    fire_log_order: ["visitor", "resident", "staff", "contractor", "industry professional", "other"],

    loaded: false,
    unauthorized: "",
    error: "",
    errors: ""
  };

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const fire_log_order = this.reorder(
      this.state.fire_log_order,
      result.source.index,
      result.destination.index
    );

    this.setState({
      fire_log_order: fire_log_order
    });
  }

  handleSubmit(event) {
    this.setState({
      success: false,
      updating: true
    })
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "organisation_settings": {
       "fire_log_order": this.state.fire_log_order
      }
    })

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/organisation_settings/0`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({error: JSON.stringify(response.body)})
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({
          fire_log_order: data.fire_log_order,

          errors: "",
          updating: false
        })
        sessionStorage.setItem("updateSuccess", "Fire Log Settings updated successfully!");
        this.props.updatePopupCallback(Math.random())
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderSaveButton() {
    if (adminUser()) {
      return (
        <input className="top-padding" type="submit" disabled={this.state.updating} value="Save" />
      )
    }
    else {
      return <input className="top-padding" type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  indexBackground(index) {
    if (index % 2 === 0) {
      return "#eee"
    }
    else {
      return "#fff"
    }
  }

  render() {
    const { fire_log_order, loaded, unauthorized, errors, error } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/> 
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      const getItemStyle = (isDragging, draggableStyle, index) => ({
        backgroundColor: isDragging ? "lightgreen" : this.indexBackground(index),
        ...draggableStyle
      });

      return (
        <div>
          <form className="settings big-settings segment-settings top-padding" onSubmit={this.handleSubmit}>
            <div className="segment">
              <HelpText className="small-bottom-padding" page={'fire_log_settings'} section={'order'} />
              <br/>
              {renderErrors(errors, "fire_log_order")}
              <DragDropContext onDragEnd={this.onDragEnd} className="table big-table">
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} className="drag-and-drop" ref={provided.innerRef} >
                      <div className="row three-columns heading">
                        <div className="th"></div>
                        <div className="th">Order</div>
                        <div className="th">Type of Person</div>
                      </div>

                      {fire_log_order.map((type_of_person, index) => (
                        <Draggable key={`key-${type_of_person}`} draggableId={`key-${type_of_person}`} index={index}>
                          {(provided, snapshot) => (
                            <div id={type_of_person} className="row three-columns" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, index)} >
                              <div className="td drag-icon"><FontAwesomeIcon icon={faArrowsAlt} /></div>
                              <div className="td">{index + 1}</div>
                              <div className="td">{capitalizeAllWords(type_of_person)}</div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              { this.renderSaveButton() }
              { renderErrorWarning(errors) }
            </div>
          </form>
        </div>
      );
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/organisation_settings/0`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          "fire_log_order": data.fire_log_order,

          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}


export default UpdateFireLogSettings;
