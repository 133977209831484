import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import Page from '../../components/shared/Page';

class WorkedHoursIndex extends Component {
  state = {

    locations: [],

    locationsLoaded: false,
    error: null,
    unauthorized: null
  }

  backgroundImage(background_image) {
    if (background_image !== null) {
      return (
        `url(${process.env.REACT_APP_ROOT_DOMAIN}/${background_image})`
      );
    }
  }

  render() {
    const { locations, locationsLoaded, error, unauthorized } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (locationsLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (locationsLoaded) {
      return (
        <div>
          <Page title="Worked Hours">
            <div className="widgets">
              <h3>Select a Location</h3>

              {locations.map((location) => (
                this.renderLocationBox(location)
              ))}
            </div>
          </Page>
        </div>
      );
    }
  }

  renderLocationBox(location) {
    return (
      <Link to={`/worked-hours/${location.id}`}>
        <div className="widget locations-component full-background" style={{ backgroundImage: this.backgroundImage(location.background_image) }}>
          <div className="fade">
            <h3 className="title">{location.name}</h3>
          </div>
        </div>
      </Link>
    )
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        const filteredLocations = data.filter(location => location.staff_fulfilment)

        localStorage.setItem("staff_fulfilment_access", filteredLocations.length > 0)

        this.setState({ locations: filteredLocations, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))
  }
}

export default WorkedHoursIndex;
